import {
  AcUnitRounded,
  AccountCircleRounded,
  CheckCircle,
  ComputerOutlined,
  DehazeSharp,
  GppGood,
  MobileScreenShareOutlined,
  WebAssetOffOutlined,
  WebStories,
} from "@mui/icons-material";

import { React, useState } from "react";

import { Link as ScrollLink } from "react-scroll";

const backgroundImageUrl = 'url("/images/bgimage.jpg")';

function Home() {
  const [isclick, setisclick] = useState(false);
  const handleToggle = () => {
    if (isclick === false) {
      setisclick(true);
    } else {
      setisclick(false);
    }
  };

  return (
    <div id="targethome">
      <nav className=" md:sticky md:top-0  flex  justify-between md:items-center  bg-white  md:p-4  rounded-md shadow-md md:h-24 h-16">
        <div className="w-3/12 md:mt-1 mt-3 ml-1">
          <img
            className="md:h-[90px] h-[42px] rounded-xl"
            src=".\images\logo.png"
            alt="sorry"
          ></img>
        </div>
        <div className="w-7/12">
          <div
            className={` bg-white md:static  absolute md:bg-inherit md:min-h-fit min-h-[80vh] left-0 w-full flex md:items-start place-items-start px-3 transition-transform duration-300 ${
              isclick ? "top-[7%]" : "top-[-100%]"
            }`}
          >
            <ul className="  flex flex-col md:flex-row  md:items-center md:gap-[6vw]  font-sans">
              <li className=" hover:border-b-2 hover:border-red-100 cursor-pointer font-medium">
                <ScrollLink
                  className="hover:text-gray-500"
                  to="targethome"
                  smooth={true}
                  duration={800}
                >
                  HOME
                </ScrollLink>
              </li>
              <li className="hover:border-b-2 hover:border-red-100 cursor-pointer font-medium">
                <ScrollLink
                  className="hover:text-gray-500"
                  to="targetDiv"
                  smooth={true}
                  duration={800}
                >
                  SERVICES
                </ScrollLink>
              </li>

              <li className="hover:border-b-2 hover:border-red-100 cursor-pointer font-medium">
                <ScrollLink
                  className="hover:text-gray-500"
                  to="pricing"
                  smooth={true}
                  duration={800}
                >
                  PRICING
                </ScrollLink>
              </li>
              <li className="hover:border-b-2 hover:border-red-100 cursor-pointer font-medium">
                <ScrollLink
                  className="hover:text-gray-500"
                  to="clients"
                  smooth={true}
                  duration={800}
                >
                  CLIENTS
                </ScrollLink>
              </li>
              <li className="hover:border-b-2 hover:border-red-100 cursor-pointer font-medium">
                <ScrollLink
                  className="hover:text-gray-500"
                  to="softwareguid"
                  smooth={true}
                  duration={800}
                >
                  DEMO
                </ScrollLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-3/12  flex justify-end">
          <a href="https://wa.link/0nir30">
            <button className="animate-bounce hidden  md:inline-block bg-green-500 font-bold p-3 md:w-40  rounded-full hover:bg-green-800 text-white">
              WhatsApp
            </button>
          </a>
          <DehazeSharp class="md:hidden font-light" onClick={handleToggle} />
        </div>
      </nav>

      <main>
        <div
          className=" grid md:grid-cols-2 sm:grid-cols-2   rounded-sm"
          style={{
            backgroundImage: backgroundImageUrl,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="bg-slate-400 p-2 m-4  hover:ease-in-out duration-300 hover:translate-y-1 delay-250  bg-opacity-30   rounded-md ">
            <iframe
            className="w-full h-full"
              src="https://www.youtube.com/embed/2P7TAqLMLZ4?si=32_9vDWfFsZjLEkl"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div className="cursor-pointer hover:ease-in-out duration-300 hover:translate-y-1 delay-350 p-4  bg-opacity-30 bg-sky-300 m-4 rounded-md ">
            <h1 className="text-center text-white text-2xl">
              MAKE YOUR BUSSINESS AUTOMATE AND EASY
            </h1>
            <hr className="mb-3"></hr>

            <h1 className="text-center text-cyan-100 text-2xl">
              Offer Customization In Software
            </h1>
            <h1 className="text-center text-orange-200 text-lg">
              We provide our software services 24/7
            </h1>
            <h1 className="text-center text-gray-200 text-lg">
              Give Only Anual Fee And Make Your Bussiness Online
            </h1>
            <h1 className="text-center text-cyan-100 text-2xl">
              +92 346-6063340
            </h1>
            <h1 className="text-center text-cyan-100 text-2xl">
              +92 327-6558052
            </h1>

            <div className="flex justify-center mt-20">
              <button className="bg-green-400 hover:bg-green-200 mr-6 md:p-5 p-2 md:w-40 sm:w-20 rounded-lg font-bold">
                
      Watch Demo
              </button>
              <button className="bg-red-400 hover:bg-red-200 md:p-5 p-2  md:w-40 sm:w-20 rounded-lg font-bold">
                Book Software
              </button>
            </div>
          </div>
        </div>
      </main>

      <h1
        className="text-black font-mono text-2xl text-center mt-6 mb-4"
        id="targetDiv"
      >
        SERVICES
      </h1>
      <div className="p-6 grid md:grid-cols-4 sm:grid-cols-2 gap-4">
        <div className="shadow-md bg-white cursor-pointer rounded-md p-6 hover:ease-in-out duration-300 hover:translate-y-1 delay-150 flex flex-col items-center">
          <h1 className="text-center animate-bounce">
            <WebStories />
          </h1>
          <h1 className="text-red-700  text-lg text-center">
            Website Development
          </h1>

          <p className="pt-2">
            Unlock your business's full potential with our tailored web
            development solutions. We create dynamic and user-friendly websites
            that not only elevate your online presence but also enhance customer
            engagement, ultimately driving growth and success for your brand
          </p>
         
        </div>
        <div className="shadow-md bg-white cursor-pointer rounded-md p-6 hover:ease-in-out duration-300 hover:translate-y-1 delay-150 flex flex-col items-center">
          <h1 className="text-center animate-bounce">
            <ComputerOutlined />
          </h1>
          <h1 className="text-red-700  text-lg text-center">
            Software Development
          </h1>

          <p className="pt-2">
            We provide point of sale software and software for every Bussiness
            we have potential to make your bussiness software as soon as
            possible when the commitment is done and we give maintenance support
            to our software developed.
          </p>
         
        </div>
        <div className="shadow-md bg-white cursor-pointer rounded-md p-6 hover:ease-in-out duration-300 hover:translate-y-1 delay-150 flex flex-col items-center">
          <h1 className="text-center animate-bounce">
            <MobileScreenShareOutlined />
          </h1>
          <h1 className="text-red-700  text-lg text-center">
            Mobile Application of Software
          </h1>

          <p className="pt-2">
            we provide mobile application along with pos which is used by the
            customer to do order from home as well as to be used by the salesman
            to take order from the customer to serve on the table or the
            salesman which goes in operation field can make invoices from mobile
            phone easily.
          </p>
         
        </div>

        <div className="shadow-md bg-white cursor-pointer rounded-md p-6 hover:ease-in-out duration-300 hover:translate-y-1 delay-150 flex flex-col items-center">
          <h1 className="text-center animate-bounce">
            <WebAssetOffOutlined />
          </h1>
          <h1 className="text-red-700  text-lg text-center">
            E-Commerce Website
          </h1>

          <p className="pt-2">
            We provide e-commerce webiste along with software which is connected
            with the software invocing which is fully automated system when
            customer place order automatically invoice created by the pos
          </p>
         
        </div>
      </div>
      <h1
        className="text-black font-mono text-2xl text-center mt-10"
        id="pricing"
      >
        BEST PRICING
      </h1>
      <div className="md:pl-[7rem] md:pr-[7rem] sm:pl-[2rem] sm:pr-[2rem] ">
        <div className=" px-4 mt-10  grid md:grid-cols-5 sm:grid-cols-2 gap-4 ">
          <div className="bg-[#1e294e] p-6 md:col-span-2 sm:col-span-1 rounded-md ">
            <h1 className="text-white text-xl font-medium">Starter Pack</h1>
            <h1 className="text-white text-lg font-medium">
              PKR 3000 Per Month
            </h1>
            <div className="flex flex-col mt-2">
              <div className="flex justify-start">
                <GppGood className="text-yellow-500 mr-1" />
                <p className="text-white text-xl">POS</p>
              </div>
            </div>
            <div className="flex flex-col ">
              <div className="flex justify-start">
                <GppGood className="text-yellow-500 mr-1" />
                <p className="text-white text-md">Manu Managment</p>
              </div>
              <div className="flex justify-start">
                <GppGood className="text-yellow-500 mr-1" />
                <p className="text-white text-md">Online Mode</p>
              </div>
              <div className="flex justify-start">
                <GppGood className="text-yellow-500 mr-1" />
                <p className="text-white text-md">Standard Reports</p>
              </div>
              <div className="flex justify-start">
                <GppGood className="text-yellow-500 mr-1" />
                <p className="text-white text-md">
                  03 Users (01 Admin & 01 Managment $ Staff)
                </p>
              </div>
              <div className="flex justify-start">
                <GppGood className="text-yellow-500 mr-1" />
                <p className="text-white text-md">
                  02 Training Sessions (01 Hour Each)
                </p>
              </div>

              <div className="bg-yellow-200 p-6 mt-20 rounded-md">
                <h1>GET DISCOUNT FOR ORDER NOW</h1>
              </div>
            </div>
          </div>
          <div className=" md:col-span-3 sm:col-span-1">
            <div className="grid  bg-[#f5f8fd] grid-cols-2 border-2 border-blue-100 rounded-lg shadow-md pl-4 pr-4 pt-2 pb-2">
              <div>
                <h1 className="text-lg text-black font-medium">
                  Inventory Managment
                </h1>

                <div className="flex ">
                  <p className="mr-2">
                    {" "}
                    <CheckCircle className="text-green-600 text-sm" /> Inventory{" "}
                  </p>
                  <p className="mr-2">
                    {" "}
                    <CheckCircle className="text-green-600 text-sm" /> Stock
                  </p>
                </div>
              </div>
              <div className="text-lg text-black font-medium text-right">
                PKR 3000/Month/Outlet
              </div>
            </div>

            <div className="mt-3 bg-[#f5f8fd] grid grid-cols-2 border-2 border-blue-100 rounded-lg shadow-md pl-4 pr-4 pt-2 pb-2">
              <div>
                <h1 className="text-lg text-black font-medium">
                  Business Intelligence
                </h1>

                <div className="flex ">
                  <p className="mr-2">
                    {" "}
                    <CheckCircle className="text-green-600 text-sm" /> Advance
                    Reporting{" "}
                  </p>
                  <p className="mr-2">
                    {" "}
                    <CheckCircle className="text-green-600 text-sm" /> Analytics
                  </p>
                </div>
              </div>
              <div className="text-lg text-black font-medium text-right">
                PKR 8000 / Month
              </div>
            </div>

            <div className="mt-3 bg-[#f5f8fd] grid grid-cols-5 border-2 border-blue-100 rounded-lg shadow-md pl-4 pr-4 pt-2 pb-2">
              <div className="col-span-3">
                <h1 className="text-lg text-black font-medium">
                  Financial Management
                </h1>

                <div className="flex ">
                  <p className="mr-2">
                    {" "}
                    <CheckCircle className="text-green-600 text-sm" /> Chart Of
                    Accounts{" "}
                  </p>
                  <p className="mr-2">
                    {" "}
                    <CheckCircle className="text-green-600 text-sm" /> Financial
                    Reporting
                  </p>
                </div>
              </div>
              <div className="text-lg text-black font-medium text-right col-span-2">
                PKR 2000 / Month
              </div>
            </div>

            <div className="mt-3 bg-[#f5f8fd] grid grid-cols-2 border-2 border-blue-100 rounded-lg shadow-md pl-4 pr-4 pt-2 pb-2">
              <div>
                <h1 className="text-lg text-black font-medium">
                  Guest Engagement
                </h1>

                <div className="flex ">
                  <p className="mr-2">
                    {" "}
                    <CheckCircle className="text-green-600 text-sm" /> CRM{" "}
                  </p>
                  <p className="mr-2">
                    {" "}
                    <CheckCircle className="text-green-600 text-sm" /> Loyalty
                  </p>
                  <p className="mr-2">
                    {" "}
                    <CheckCircle className="text-green-600 text-sm" /> Guest
                    Feedback
                  </p>
                </div>
              </div>
              <div className="text-lg text-black font-medium text-right">
                PKR 4000 / Month
              </div>
            </div>

            <div className="mt-3 bg-[#f5f8fd] grid grid-cols-2 border-2 border-blue-100 rounded-lg shadow-md pl-4 pr-4 pt-2 pb-2">
              <div>
                <h1 className="text-lg text-black font-medium">
                  Production Management
                </h1>

                <div className="flex ">
                  <p className="mr-2">
                    {" "}
                    <CheckCircle className="text-green-600 text-sm" />{" "}
                    Commissary{" "}
                  </p>
                  <p className="mr-2">
                    {" "}
                    <CheckCircle className="text-green-600 text-sm" /> Composite
                    Products
                  </p>
                </div>
              </div>
              <div className="text-lg text-black font-medium text-right">
                PKR 2000 / Month
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className=" md:pl-30 pl-10 pr-10 pb-5 md:pr-30 md:pb-10 md:pt-20 "
        id="clients"
      >
        <h1 className="text-2xl text-amber-600 text-center p-6">
          What Our Client Says About Us
        </h1>

        <div className="md:p-10   grid md:grid-cols-3 sm:grid-cols-2 gap-4 ">
          <div className="shadow-md m:p-10 p-2  flex justify-start  bg-white rounded-xl">
            <div className=" text-blue-300 ">
              <AccountCircleRounded className="animate-bounce" />
            </div>
            <div>
              <h1 className="text-xl text-orange-800">
                Wholesale Distributers
              </h1>
              <h1>Vital Distributers</h1>
              <p className="mt-3 text-sm">
                The Chow RMS delivered an impressive 24% expense reduction,
                revolutionizing our bottom line. A game-changer for any
                restaurant!
              </p>
              <div className="flex justify-between">
                <div className="mt-2 text-yellow-300 text-2xl">
                  <AcUnitRounded className="text-4xl" />
                </div>
                <div className="mt-2">
                  <h1 className="text-3xl text-blue-800">24%</h1>
                  <p>Expense Decreased</p>
                </div>
              </div>
            </div>
          </div>

          <div className="shadow-md m:p-10 p-2  flex justify-start  bg-white rounded-xl">
            <div className=" text-blue-300 ">
              <AccountCircleRounded className="animate-bounce" />
            </div>
            <div>
              <h1 className="text-xl text-orange-800">Surgical Indus</h1>
              <h1>Operational Manager</h1>
              <p className="mt-3 text-sm">
                Chow RMS system deserves a standing ovation! With a 34%
                productivity increase, it's like having a magician in the
                kitchen. Abracadabra, and the orders are out!
              </p>
              <div className="flex justify-between">
                <div className="mt-2 text-yellow-300 text-2xl">
                  <AcUnitRounded className="text-4xl" />
                </div>
                <div className="mt-2">
                  <h1 className="text-3xl text-blue-800">54%</h1>
                  <p>Productivity Increase</p>
                </div>
              </div>
            </div>
          </div>

          <div className="shadow-md m:p-10 p-2 flex justify-start  bg-white rounded-xl">
            <div className=" text-blue-300 ">
              <AccountCircleRounded className="animate-bounce" />
            </div>
            <div>
              <h1 className="text-xl text-orange-800">Book Shops</h1>
              <h1>CEO</h1>
              <p className="mt-3 text-sm">
                The Chow RMS fueled a remarkable 60% increase in revenue, making
                it an essential asset for our business growth. Highly
                recommended!
              </p>
              <div className="flex justify-between">
                <div className="mt-2 text-yellow-300 text-2xl">
                  <AcUnitRounded className="text-4xl" />
                </div>
                <div className="mt-2">
                  <h1 className="text-3xl text-blue-800">34%</h1>
                  <p>Profit Increased</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" md:pl-40 sm:pl-40 pl-10 pr-10 pb-10 md:pr-40 sm:pr-40 md:pb-20 sm:pb-40 ">
        <h1 className="text-2xl text-yellow-800 text-center font-medium">
          RECENT PROJECTS
        </h1>
        <div className="grid md:grid-cols-2 sm:grid-cols-2 ">
          <div className=" md:p-10 sm:p-10 pb-10 shadow-lg rounded-md underline">
            <h1 className="text-lg text-yellow-800 text-center">
              Recent E-Commerce Projects
            </h1>
            <hr></hr>
            <div className="text-center">
              <a href="https://afzalhardware.com/" className="text-black ">
                Afzalhardware.com
              </a>
              <br />
              <a href="https://behgalsports.com" className="text-black">
                behgalsports.com
              </a>
              <br />
              <a href="https://poshinds.com/" className="text-black">
                poshinds.com
              </a>
            </div>
          </div>

          <div className=" p-10 shadow-lg rounded-md underline">
            <h1 className="text-lg text-yellow-800 text-center">
              Recent Deployed Software
            </h1>
            <hr></hr>
            <div className="text-center">
              <a
                href="https://punjabrestaurantandtikkashop.com/"
                className="text-black "
              >
                Punjab Restaurant Software POS
              </a>
              <br />
              <a href="https://mrzgadgets.com/" className="text-black ">
                Mrzgadgets Mobile Shop in canada
              </a>
              <br />
              <a
                href="https://hassanbookdepo.developingdesk.com/"
                className="text-black"
              >
                Hassanbook Depo Distributers
              </a>
              <br />

              <a
                href="https://salmantraders.developingdesk.com/"
                className="text-black"
              >
                Salmantraders Vital Tea Distributers
              </a>
              <br />

              <a href="https://roshan-pk.com/" className="text-black">
                Roshan Pk Cement Distributers
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" md:pl-40 sm:pl-40 pl-10 pr-10 pb-3 md:pr-40 sm:pr-40 md:pb-5 sm:pb-40 "
        id="softwareguid"
      >
        <h1 className="text-2xl text-yellow-800 text-center font-medium">
          SOFTWARE GUID
        </h1>
        <div className="pt-2 grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
          <div className=" shadow-lg rounded-md p-2">
            <h1 className="bg-gray-900 p-2 text-white text-center align-middle">
              How to Add Products
            </h1>

            <iframe
              title="pos demo"
              class="w-full aspect-video "
              src="https://www.youtube.com/embed/2P7TAqLMLZ4?si=6T-0R9kmzYeFpy9M"
            ></iframe>
          </div>
          <div className=" shadow-lg rounded-md p-2">
            <h1 className="bg-gray-900 p-2 text-white text-center align-middle">
              How to Register User Accounts
            </h1>

            <iframe
              title="pos demo"
              class="w-full aspect-video "
              src="https://www.youtube.com/embed/QmE7qA60Dfc?si=C_fw3MrdiD84Ab_a"
            ></iframe>
          </div>
          <div className=" shadow-lg rounded-md p-2">
            <h1 className="bg-gray-900 p-2 text-white text-center align-middle">
              How to Add Recieving Rrom Customers
            </h1>

            <iframe
              className="w-full aspect-video"
              src="https://www.youtube.com/embed/X82QuURLPdE?si=RCOY2b77Y6akPY0-"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="md:pl-40  pl-10 md:pr-40 pr-10 bg-slate-100" id="aboutus">
        <h1 className="text-4xl text-center mt-8">ABOUT US</h1>

        <div className=" grid justify-items-end md:grid-cols-2 sm:grid-cols-1 gap-8">
          <div>
            <img
              alt="sorry"
              className="h-20 rounded-lg"
              src=".\images\logo.png"
            ></img>

            <p className="mt-12">
              Simplify your hospitality operations remotely with our digital
              solutions. Focus on what matters while we handle the details,
              allowing your business to thrive.
            </p>
          </div>
          <div className=" text-right p-3 mt-28">
            <h1> Locations: Daska, Sialkot, Pakistan</h1>

            <h1>Email: contact@developingdesk.com</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
