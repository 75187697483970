import React from 'react'

function Aboutus() {
  return (
    <div>
      <h1 className='text-red-100 text-2xl'>helo my dear</h1>
    </div>
  )
}

export default Aboutus
