import { createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./DefaultLayout";
import Aboutus from "./Aboutus";
import Notfound from "./Notfound";
import Demo from "./Demo";




const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "/aboutus",
        element: <Aboutus />,
      },
     

    ],
  },

  
  {
    path: "*",
    element: <Notfound />,
  },
  {
    path: "/demouser",
    element: <Demo />,
  },  
 
]);
export default router;
