import React from 'react';
import ReactDOM from 'react-dom/client';
import './home.css';


import { RouterProvider } from 'react-router-dom';
import router from './router';
import { ContextProvider } from './Context/ContextProvider';
import axios from 'axios';
axios.defaults.baseURL="http://localhost:8000/";
axios.defaults.headers.post['Content-Type']='application/json';
axios.defaults.headers.post['Accept']='application/json';
axios.defaults.withCredentials = true;
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
   <ContextProvider>
  <RouterProvider router={router} /> 
  </ContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

