import {  VerifiedUserSharp, Warning } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function Demo() {
const [searchQuery,setSearchQuery]=useState('');

  const [defaultCountry, setDefaultCountry] = useState('');
  const [country, setCountry] = useState([]);
  const [defaultDivision, setDefaultDivision] = useState('');
  const [division, setDivision] = useState([]);

  const [defaultDistrict, setDefaultDistrict] = useState('');
  const [district, setDistrict] = useState([]);
  const [defaultArea, setDefaultArea] = useState('');
  const [area, setArea] = useState([]);
  const [defaultWard, setDefaultWard] = useState('');
  const [ward, setWard] = useState([]);
  const [userName,setUserName]=useState('');
  const [userCnic,setUserCnic]=useState('');
  const [dialog,setDialog]=useState('');
  useEffect(() => {
    axios.get('/api/countries', { withCredentials: true }).then((res) => {
      setCountry(res.data.countries);

      // Check if the countries array is not empty before setting default value
      if (res.data.countries.length > 0) {
        setDefaultCountry(res.data.countries[0]['id']);
      }
    });
  }, []);

  useEffect(() => {
    if (defaultCountry) {
      axios
        .get(`/api/getdivisions/${defaultCountry}`)
        .then((response) => {
          setDivision(response.data.divisions);

          // Check if the divisions array is not empty before setting default value
          if (response.data.divisions.length > 0) {
            setDefaultDivision(response.data.divisions[0]['id']);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [defaultCountry]);

  useEffect(() => {
    if (defaultDivision) {
      axios
        .get(`/api/getdistricts/${defaultDivision}`)
        .then((response) => {
          setDistrict(response.data.districts);

          // Check if the divisions array is not empty before setting default value
          if (response.data.districts.length > 0) {
            setDefaultDistrict(response.data.districts[0]['id']);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [defaultDivision]);


  useEffect(() => {
    if (defaultDistrict) {
      axios
        .get(`/api/getareas/${defaultDistrict}`)
        .then((response) => {
          setArea(response.data.area);
console.log(response.data.area)
          // Check if the divisions array is not empty before setting default value
          if (response.data.area.length > 0) {
            setDefaultArea(response.data.area[0]['id']);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [defaultDistrict]);


  useEffect(() => {
    if (defaultArea) {
      axios
        .get(`/api/getwards/${defaultArea}`)
        .then((response) => {
          setWard(response.data.wards);

          // Check if the divisions array is not empty before setting default value
          if (response.data.wards.length > 0) {
            setDefaultWard(response.data.wards[0]['id']);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [defaultArea]);

  const handleSearchChange = (event) => {

    setSearchQuery(event.target.value);
 

  };

const handleSearch=()=>{
  
const data={
'wardid':defaultWard,
'cnic':searchQuery

}

  axios.post(`/api/searchuser`,data).then(response=>{
    //setInvoices(response.data.user.data);
   
    if(response.data.user){
      setUserName(response.data.user['name']);
      setUserCnic(response.data.user['cnic']);
    }

setDialog(1);

  })

}

const handleSearchNew=()=>{

  setDialog('');
  setUserName('');
  setUserCnic('');
}


  return (
    <div className="p-4 m-10 bg-white shadow-md">
<div  style={{transition:'ease-in-out',transitionDelay:'0s',transitionDuration:'1s', top:dialog!==''?'100px':'-300px', borderRadius:'10px', padding:'20px',position:'absolute',left:'35%',backgroundColor:'white',width:'500px',boxShadow:'3px 3px 2px gray'}}>
<center>
  {
    userName!==''?
<VerifiedUserSharp style={{fontSize:'70px',color:'green'}}/>
:
<Warning style={{fontSize:'70px',color:'orange'}}></Warning>
}
</center>
<h5 className='text-black text-xl'> 

Name:{userName!==''?userName:'not found'}

</h5>
<h5 className='text-black text-xl'>
Cnic:{userCnic !==''?userCnic:'not found'}
</h5>
<div style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
<div><button className='bg-green-800 p-4 rounded-md text-white' style={{marginRight:'8px'}} onClick={handleSearchNew}>Ok,Search New</button></div>

</div>
</div>

      <h1 className="text-center text-2xl text-red-500 font-medium">SEARCH USER</h1>
      <hr></hr>

      <div className="flex mt-10 justify-between ">
        <div>
          <select
            value={defaultCountry}
            className="p-4 pl-8 pr-8 w-45"
            onChange={(e) => setDefaultCountry(e.target.value)}
          >
            {country &&
              country.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}{' '}
                </option>
              ))}
          </select>
        </div>

        <div>
          <select
            value={defaultDivision}
            className="p-4 pl-8 pr-8 w-45"
            onChange={(e) => setDefaultDivision(e.target.value)}
          >
            {division &&
              division.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        
        <div>
          <select
            value={defaultDistrict}
            className="p-4 pl-8 pr-8 w-45"
            onChange={(e) => setDefaultDistrict(e.target.value)}
          >
            {district &&
              district.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>

         
        <div>
          <select
            value={defaultArea}
            className="p-4 pl-8 pr-8 w-45"
            onChange={(e) => setDefaultArea(e.target.value)}
          >
            {area &&
              area.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>

        <div>
          <select
            value={defaultWard}
            className="p-4 pl-8 pr-8 w-45"
            onChange={(e) => setDefaultWard(e.target.value)}
          >
            {ward &&
              ward.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
<div>

  <input className='p-2 shadow-lg w-400' placeholder='Search User' value={searchQuery} onChange={handleSearchChange}></input>
  </div>
  <div>
<button className='p-3 w-15 bg-blue-700 rounded-lg text-white' onClick={handleSearch}>Fetch User</button>

  </div>

      </div>
    </div>
  );
}

export default Demo;
