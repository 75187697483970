import React from 'react'
import Home from './Home'


function DefaultLayout() {
  return (
    <div>
  
<Home></Home>
 
    </div>

  )
}

export default DefaultLayout
